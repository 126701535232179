var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"fixed-grid mx-2 has-1-cols quote-builder-cells-holder",class:{
      // when in multi column view (dropdown view), only have two columns if screen is atleast 1024px or cant see some items
      'has-1-cols': _vm.selectedViewType == _vm.viewTypes.singleColView || _vm.selectedViewType == _vm.viewTypes.tabbedView,
      'has-2-cols-desktop': _vm.selectedViewType == _vm.viewTypes.multiColView}},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"cell"},[(((_vm.selectedTab.type == _vm.ItemCategoryTypes.RR || _vm.selectedTab.type == _vm.ItemCategoryTypes.REP
            ||_vm.selectedTab.type == _vm.ItemCategoryTypes.PAINT|| _vm.selectedViewType != _vm.viewTypes.tabbedView)
            && ((_vm.isAvailableItemsOpen && _vm.avaliableItemsType === 'LABOUR') || !_vm.isAvailableItemsOpen )))?_c('quote-builder-labour-component',{attrs:{"value":_vm.value,"selected-tab":_vm.selectedTab,"is-full-width":_vm.isFullWidth,"view-types":_vm.viewTypes,"vehicle":_vm.vehicle,"edit-item-obj":_vm.editItemObj,"selected-view-type":_vm.selectedViewType,"next-line-number":_vm.nextLineNumber},on:{"handleUpdate":newVal => _vm.$emit('handleUpdate', newVal),"onOpgCodeChanged":function($event){return _vm.$emit('onOpgCodeChanged')},"onItemCopied":_vm.onItemCopied,"focusNextRow":item => _vm.handleFocusNextRow(item),"newItemAdded":_vm.newItemAdded,"handleItemRowClick":_vm.handleItemRowClick,"itemRowClicked":_vm.handleItemRowClick,"handlePresentAs":itemId => _vm.$emit('handlePresentAs', itemId)}}):_vm._e(),(((_vm.selectedTab.type == _vm.ItemCategoryTypes.MECH || _vm.selectedViewType != _vm.viewTypes.tabbedView)
            && ((_vm.isAvailableItemsOpen && _vm.avaliableItemsType === 'OTHER') || !_vm.isAvailableItemsOpen)))?_c('quote-builder-other-labour-component',{attrs:{"value":_vm.value,"selected-tab":_vm.selectedTab,"is-full-width":_vm.isFullWidth,"view-types":_vm.viewTypes,"vehicle":_vm.vehicle,"edit-item-obj":_vm.editItemObj,"selected-view-type":_vm.selectedViewType,"next-line-number":_vm.nextLineNumber},on:{"handleUpdate":newVal => _vm.$emit('handleUpdate', newVal),"onItemCopied":_vm.onItemCopied,"focusNextRow":item => _vm.handleFocusNextRow(item),"newItemAdded":_vm.newItemAdded,"handleItemRowClick":_vm.handleItemRowClick,"itemRowClicked":_vm.handleItemRowClick,"handlePresentAs":itemId => _vm.$emit('handlePresentAs', itemId)}}):_vm._e()],1),_c('div',{staticClass:"cell"},[((_vm.selectedTab.type == _vm.ItemCategoryTypes.PART || _vm.selectedViewType != _vm.viewTypes.tabbedView)
            && ((_vm.isAvailableItemsOpen && _vm.avaliableItemsType === 'PARTS') || !_vm.isAvailableItemsOpen))?_c('div',{staticClass:"mb-4"},[_c('quote-builder-part-component',{attrs:{"value":_vm.value,"selected-tab":_vm.selectedTab,"is-full-width":_vm.isFullWidth,"view-types":_vm.viewTypes,"edit-item-obj":_vm.editItemObj,"selected-view-type":_vm.selectedViewType,"vehicle":_vm.vehicle,"next-line-number":_vm.nextLineNumber,"is-audanet":_vm.value.isAudaNet || _vm.value.isAudaBridge},on:{"handleUpdate":newVal => _vm.$emit('handleUpdate', newVal),"onItemCopied":_vm.onItemCopied,"focusNextRow":item => _vm.handleFocusNextRow(item),"newItemAdded":_vm.newItemAdded,"handleItemRowClick":_vm.handleItemRowClick,"itemRowClicked":_vm.handleItemRowClick,"handlePresentAs":itemId => _vm.$emit('handlePresentAs', itemId)}})],1):_vm._e(),((_vm.selectedTab.type == _vm.ItemCategoryTypes.MISC || _vm.selectedViewType != _vm.viewTypes.tabbedView)
            && ((_vm.isAvailableItemsOpen && _vm.avaliableItemsType === 'MISC') || !_vm.isAvailableItemsOpen))?_c('div',{staticClass:"mb-4"},[_c('quote-builder-misc-component',{attrs:{"value":_vm.value,"selected-tab":_vm.selectedTab,"is-full-width":_vm.isFullWidth,"view-types":_vm.viewTypes,"edit-item-obj":_vm.editItemObj,"selected-view-type":_vm.selectedViewType,"vehicle":_vm.vehicle,"next-line-number":_vm.nextLineNumber},on:{"handleUpdate":newVal => _vm.$emit('handleUpdate', newVal),"onItemCopied":_vm.onItemCopied,"focusNextRow":item => _vm.handleFocusNextRow(item),"newItemAdded":_vm.newItemAdded,"handleItemRowClick":_vm.handleItemRowClick,"itemRowClicked":_vm.handleItemRowClick,"handlePresentAs":itemId => _vm.$emit('handlePresentAs', itemId)}})],1):_vm._e(),((_vm.selectedTab.type == _vm.ItemCategoryTypes.SUBL || _vm.selectedViewType != _vm.viewTypes.tabbedView)
          && ((_vm.isAvailableItemsOpen && _vm.avaliableItemsType === 'SUBLETS') || !_vm.isAvailableItemsOpen))?_c('div',[_c('quote-builder-sublet-component',{attrs:{"value":_vm.value,"selected-tab":_vm.selectedTab,"is-full-width":_vm.isFullWidth,"view-types":_vm.viewTypes,"edit-item-obj":_vm.editItemObj,"selected-view-type":_vm.selectedViewType,"vehicle":_vm.vehicle,"next-line-number":_vm.nextLineNumber},on:{"handleUpdate":newVal => _vm.$emit('handleUpdate', newVal),"onItemCopied":_vm.onItemCopied,"focusNextRow":item => _vm.handleFocusNextRow(item),"newItemAdded":_vm.newItemAdded,"handleItemRowClick":_vm.handleItemRowClick,"itemRowClicked":_vm.handleItemRowClick,"handlePresentAs":itemId => _vm.$emit('handlePresentAs', itemId)}})],1):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }