<template>
  <div class="item-row-quote-builder-labour quote-builder-item-row type-mech"
    :id="item.quoteItemId"
    :class="[{'has-background-grey-lighter is-selected' : isSelected, 'is-open' : isSelected && !isFullWidth}, defaultRowColourClass(item, getItemAssessments(item), displayDeleted), rowDisplayStatus(item, getItemAssessments(item))]">

    <!--------------------------------------------------------------- Main Template --------------------------------------->
    <quote-builder-other-labour-item-template :is-full-width="isFullWidth"
      :is-quote-hour="isQuoteTypeHour"
      :is-fully-expanded="isSelected && !isFullWidth"
      :is-selected="isSelected"
      :is-header="false"
      @handleClick="handleClick">

      <!------- Description ------->
      <template v-slot:description>
        <div :content="getTooltipCommunicationHistoryForField(item, 'itemDesc')"
          v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'itemDesc')"
          :class="[defaultCellColourClass(item,'itemDesc')]"
          v-focus-next:callback="handleTransitionNextRow"
          :style="{ 'width': '100%' }"
          class="is-flex">
          <input v-if="isSelected && (!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock) && !isItemRemoved(item, getItemAssessments(item))"
            class="input"
            :class="[defaultRowColourClass(item, getItemAssessments(item), false), !isValidItemDesc(item.quoteItemId) ? 'is-danger': '']"
            v-focus-next-element-on-arrow-directive:callback="handleArrowKeys"
            placeholder="Item Description"
            :id="item.quoteItemId + '-itemDesc'"
            v-model="item.itemDesc">
          <span v-else
            :id="item.quoteItemId + '-itemDesc'"
            :class="{'has-text-danger': !item.itemDesc}">
            {{ item.itemDesc || 'Item Description' }}
          </span>
        </div>
      </template>

      <!------------------- Report ------------>
      <template v-slot:report>
        <div class="is-clickable"
          :class="{'disabled' : item.deleteOtherItem || !canMakeReportOnly(item)}"
          @click="$event => updateReport(item, $event)">
          <div class="is-flex ">
            <span v-tippy="{ theme: 'secondary', enabled: true }"
              content="Report"
              :class="item.reportOnly ? 'is-dark ' : 'is-light'"
              class="tag is-primary p-1 px-2">R
            </span>
          </div>
        </div>
      </template>de-flexiquote-1-00-00-12

      <!------------------- Rates ------------>
      <template v-slot:rates>
        <div :class="[{ 'tooltip' : isItemInvoiced(item) === true}]"
          data-tooltip="Item rate is already invoiced!">
          <vue-numeric v-if="isSelected && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item)) && !isItemInvoiced(item)"
            :id="item.quoteItemId + '-rate'"
            class="input has-text-right skip-tab"
            placeholder="Rate"
            :class="[ defaultRowColourClass(item, getItemAssessments(item), false), item.rate !== getLabourRate(item.itemType, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate ? 'is-warning' : '' ]"
            :min="0"
            :precision="2"
            v-focus-next:callback="handleTransitionNextRow"
            v-focus-next-element-on-arrow-directive:callback="handleArrowKeys"
            @blur="onRateChange(item)"
            v-model.number="item.rate" />
          <div v-else
            class="is-flex is-justify-content-end is-quote-item-field-editable"
            :id="item.quoteItemId + '-rate'">
            {{ $filters.formatCurrency(item.rate, $userInfo.locale, false, 2) }}
          </div>
        </div>
      </template>

      <!------------------- Hour   ------------>
      <template v-slot:hour_value
        :class="[defaultCellColourClass(item,'hourValue')]"
        :content="getTooltipCommunicationHistoryForField(item, 'hourValue')"
        v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'hourValue')">
        <div class="is-flex is-justify-content-end">
          <vue-numeric v-if="isSelected && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
            :id="item.quoteItemId + '-hourValue'"
            class="input has-text-right"
            placeholder="h(s)"
            :class="defaultRowColourClass(item, getItemAssessments(item), false)"
            :minus="true"
            v-focus-next:callback="handleTransitionNextRow"
            v-focus-next-element-on-arrow-directive:callback="handleArrowKeys"
            @blur="onRateChange(item)"
            :precision="2"
            v-model="item.hourValue" />
          <span v-else-if="isQuoteHour"
            class="is-quote-item-field-editable"
            :id="item.quoteItemId + '-hourValue'">
            {{ item.hourValue?.toFixed(2) }}
          </span>
          <span v-else
            class="is-quote-item-field-editable"
            :id="item.quoteItemId + '-hourValue'">
            {{ $filters.formatCurrency(item.hourValue, $userInfo.locale, false, 2) }}
          </span>
        </div>
      </template>

      <!------------------- Total Value ------------>
      <template v-slot:total_value>
        <div class="is-flex is-justify-content-end is-align-items-center">
          <span>
            {{ $filters.formatCurrency( item.dollarValue, $userInfo.locale, false, 2) }}
          </span>
        </div>
      </template>

      <!------------------- Present As ------------>
      <template v-slot:present_as>
        <present-as-component :item="item"
          @handlePresentAs="$emit('handlePresentAs', item.quoteItemId)" />
      </template>

      <!---------------------- Comment   ---------------->
      <template v-slot:comment
        v-if="item.itemComment">
        <div v-tippy="{ theme: 'secondary', enabled: true, content:item.itemComment }">
          <span class="mdi has-text-primary is-primary mdi-18px mdi-message-reply-text mr-1 is-clickable" />
        </div>
      </template>

      <!--------------------- Assessment Status  ----------->
      <template v-slot:assessment_status>
        <supplementary-icon v-if="innerValue.quoteAssessments.length > 0"
          :item="item"
          :item-assessments="getItemAssessments(item)"
          :next-assessment-number="nextSupplemetryNo"
          :quoting-method="innerValue.quotingMethod"
          :icon-size="4"
          class="icon" />
      </template>

      <!------------------- Delete button ------------>
      <template v-slot:delete
        v-if="!audanetLock && !isItemRemoved(item, getItemAssessments(item)) && (item.isDeleted === false || item.deleted === false)">
        <button class=" button is-ghost is-small tooltip p-0 mx-1"
          data-tooltip="Delete Labour Item"
          @click="$emit('deleteItem', item)">
          <span class="mdi mdi-delete mdi-24px has-text-danger is-clickable" />
        </button>
      </template>

      <!------------------- Drag button ------------>
      <template v-slot:draggable>
        <button class="button is-ghost is-small tooltip item-drag-handle px-0"
          data-tooltip="Drag"
          :disabled="innerValue.readOnly || readOnlyView">
          <span class="mdi mdi-drag-vertical mdi-24px has-text-primary is-clickable" />
        </button>
      </template>

      <!----------------- Line No  ------------------------>
      <template v-slot:lineNo>
        <div v-tippy="{ theme: 'secondary', enabled: true, content: 'Line Number' }">
          <vue-numeric v-if="(!innerValue.readOnly && $userInfo.isSupportUser && !readOnlyView) && !isItemRemoved(item, getItemAssessments(item))"
            class="input has-text-right"
            :class="defaultRowColourClass(item, getItemAssessments(item), false)"
            v-model.number="item.lineNumber"
            v-focus-next:callback="handleTransitionNextRow"
            :precision="0"
            separator="" />
          <span v-else>{{ item.lineNumber }}</span>
        </div>
      </template>

      <!----------------- Item No  ------------------------>
      <template v-slot:itemNo>
        <div v-tippy="{ theme: 'secondary', enabled: true, content: 'Item Number' }">
          <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
            class="input"
            :class="[defaultRowColourClass(item, getItemAssessments(item), false), !isValidItemNo(item.quoteItemId) ? 'is-danger': '']"
            placeholder="Item No."
            v-model="item.itemNo"
            v-focus-next:callback="handleTransitionNextRow"
            @input="setItemStatus(item)">
          <span v-else>{{ item.itemNo }}</span>
        </div>
      </template>

      <!------------------Item Status  -------------------->
      <template v-slot:itemStatus>
        <div v-tippy="{ theme: 'secondary', enabled: true, content: 'Item Status' }">
          <input v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
            class="input is-capitalized"
            v-model="item.itemStatus"
            v-focus-next:callback="handleTransitionNextRow">
          <span v-else>{{ item.itemStatus }}</span>
        </div>
      </template>

    </quote-builder-other-labour-item-template>

  </div>

</template>

<script>
import vueNumeric from '@/components/VueNumeric/vue-numeric.vue'
import { ItemTypes } from '@/enums'
import QuoteBuilderOtherLabourItemTemplate from '../../templates/QuoteBuilderOtherLabourItemTemplate.vue'
// import BaseModalEx from '@/components/BulmaModal/BaseModalEx'
import {
  QuoteItemValidationMixin,
  QuoteItemsMixin,
  QuoteAssessmentMixin,
  QuoteItemDifferenceMixin,
  QuoteOtherLabourMixin
} from '@/views/quote/quoteBuilder/mixins'
import PresentAsComponent from '../../components/PresentAsComponent.vue'
import supplementaryIcon from '@/views/quote/components/SupplementaryIcon.vue'
import { FocusNext, FocusNextElementOnArrowDirective } from '@/components/directives'

export default {
  name: 'QuoteBuilderOtherLabourItem',
  components: { vueNumeric, QuoteBuilderOtherLabourItemTemplate, PresentAsComponent, supplementaryIcon },
  directives: {
    FocusNext,
    FocusNextElementOnArrowDirective
  },
  mixins: [QuoteItemValidationMixin, QuoteItemsMixin, QuoteAssessmentMixin, QuoteItemDifferenceMixin, QuoteOtherLabourMixin],
  props: {
    innerValue: {
      type: Object,
      required: true
    },
    // in a single row view, the row is full width
    isFullWidth: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    editItemId: null,
    nextLineNumber: {
      type: Number,
      default: 0
    },
    readOnlyView: {
      type: Boolean,
      default: false
    },
    audanetLock: {
      type: Boolean,
      default: false
    },
    isQuoteTypeHour: {
      type: Boolean,
      default: false
    },
    vehicle: {
      type: Object,
      required: true
    },
    itemsAssessments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      displayDeleted: false,
      itemType: 'OTHER',
      filter: {
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 100
      }
    }
  },
  computed: {
    // your computed properties go here
    isSelected() {
      return this.item.quoteItemId === this.editItemId
    },
    ItemTypes() {
      return ItemTypes
    },
    isQuoteHour() {
      return this.isQuoteTypeHour
    },
    nextSupplemetryNo() {
      const nextSuppNo = Math.max(...this.innerValue.quoteAssessments.map((i) => i.subQuoteNo)) + 1
      return nextSuppNo
    }
  },
  methods: {
    handleArrowKeys(nextElementId, fieldId) {
      console.log('handleArrowKeys', fieldId)
      this.$eventHub.$emit('handleArrowKeyFocus', nextElementId, fieldId)
    },
    handleTransitionNextRow() {
      this.$emit('focusNextRow')
    },
    handleClick(event, close = false) {
      event.stopPropagation()
      this.$emit('itemRowClicked', this.item, event, close)
    },

    onRateChange(item) {
      if (this.isQuoteHour) item.dollarValue = item.rate * item.hourValue
      else item.dollarValue = item.hourValue
    },
    updateReport(item, event) {
      // event.stopPropagation()
      if (!item.deleteOtherItem && !this.innerValue.readOnly && !this.readOnlyView && !this.audanetLock && this.canMakeReportOnly(item)) {
        item.reportOnly = !item.reportOnly
        // const itemToUpdate = this.innerValue.others.find((i) => i.quoteItemId === item.quoteItemId)
        // itemToUpdate.reportOnly = item.report
        // itemToUpdate.reportOnly = item.reportOnly
      }
    },

    getItemAssessments(item) {
      const assessments = this.itemsAssessments
      const itemAssessments = assessments.find((i) => i.quoteItemId == item.quoteItemId)
      if (!!itemAssessments) return itemAssessments.assessments
      return []
    },
    canMakeReportOnly(item) {
      const assessments = this.getItemAssessments(item)
      if (assessments.length > 0) {
        const lastAssessment = assessments[assessments.length - 1]
        const lastValues = lastAssessment[lastAssessment.length - 1]
        if (!lastValues.reportOnly) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss">
.item-row-quote-builder-labour {
  display: grid;
  height: 45px !important;
  border: 1px solid #adb5bd;
  border-radius: 5px;
  font-size: 0.9em;
  margin-bottom: 5px;
  padding: 2px;

  .center-y {
    display: flex;
    align-items: center;
  }

  .center {
    display: flex;
    justify-content: center;
  }
  .end {
    display: flex;
    justify-content: flex-end;
  }

  &.is-open {
    height: 85px !important;
  }
}

/* text overflow ellipses */
.item-row-quote-builder-labour span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>